import React from "react";
import { marked } from "marked";

const LonpaPressRelease = () => {
  const missionMarkdown = `     
  * わかりあい：   
  同じ目的で互いを尊重し協力し合う  

  * わいわい：  
  気軽に意見を言える環境  

  * わくわく：  
  仲間と新しいものを生み出す楽しさ                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
 `;

  const visionMarkdown = `
 * 公正・健全な活動を行なう
 * 多様性を尊重する
 * 共創を促す社会の実現する
 * わのプラットフォームを提供する                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
`;

  return (
    <div>
      <br />
      <br />
      <br />
      <div>
        <h2 style={{ textAlign: "center", fontSize: 15 }}>
          - Lamact Mission -
        </h2>
        <h3 style={{ textAlign: "center", fontSize: 30 }}>
          {/* <img src={wow} alt="アイコン" height="200" width="200" /> */}
          「わ」を提供する
        </h3>
        <h4 style={{ textAlign: "center", fontSize: 15 }}>
          Lamactが提供する、3つの事柄
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: marked.parse(missionMarkdown),
            }}
          />
        </div>
        <h4 style={{ textAlign: "center", fontSize: 15 }}>
          これら３つの事柄を提供できるよう
          <br />
          新しいサービスを生み出していきます。
        </h4>
        <br />
        <br />
        <hr />
        <br />
        <br />
        <h2 style={{ textAlign: "center", fontSize: 15 }}>
          {" "}
          - Lamact Vision -{" "}
        </h2>
        <h3 style={{ textAlign: "center", fontSize: 30 }}>
          {/* <img src={wow2} alt="アイコン" height="200" width="200" /> */}
          「わ」を実現する
        </h3>
        <h4 style={{ textAlign: "center", fontSize: 15 }}>
          Lamact実現していく、４つの活動方針
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: marked.parse(visionMarkdown),
            }}
          />
        </div>
        <h4 style={{ textAlign: "center", fontSize: 15 }}>
          これら４つの方針で、
          <br />
          Lamactは皆様に価値をお届けします。
        </h4>
      </div>
      <br />
      <br />
    </div>
  );
};

export default LonpaPressRelease;
