import React, { Component } from "react";
import { useWindowSize } from "react-use";

import { Grid, Card, CardContent } from "@mui/material";
import pic1 from "./1.jpg";
import pic2 from "./2.jpg";
import pic3 from "./3.jpg";

const services = [
  {
    title: "美味しいランチ",
    description: "料理上手なメンバが素晴らしいランチを提供します。",
    pic: pic1,
  },
  {
    title: "エンジニア交流",
    description:
      "共通の興味を持つエンジニアを見つけて、専門知識を共有しましょう。",
    pic: pic2,
  },
  {
    title: "みんなでゲーム",
    description: "ボードゲームやNintendo switchで楽しく交流しましょう。",
    pic: pic3,
  },
];
const styles: any = {
  top: {
    fontSize: "20px",
    width: "100%",
    textAlign: "center",
    color: "black",
  },
  middle: {
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    color: "black",
  },
};

const Services = () => {
  const { width } = useWindowSize();

  return (
    <>
      <Grid
        container
        sx={{ mt: 0, px: 2, pb: 2, backgroundColor: "#ffffff" }}
        style={{ padding: "10px" }}
      >
        {services.map((service, index) => (
          <>
            {width > 650 ? (
              <>
                {" "}
                <Grid
                  item
                  xs={4}
                  md={4}
                  key={service.title}
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    display: "flex",
                  }}
                >
                  <Card>
                    <CardContent style={{ height: "230px" }}>
                      <div style={styles.top}>{service.title}</div>
                      <img
                        src={service.pic}
                        style={{ borderRadius: "50%" }}
                        alt="大画面ロゴ"
                        height="120"
                        width="120"
                      />
                      <div style={styles.middle}>{service.description}</div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                {" "}
                <Grid
                  item
                  xs={12}
                  md={12}
                  key={service.title}
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    display: "flex",
                  }}
                >
                  <Card style={{ width: "100%" }}>
                    <CardContent style={{ height: "190px" }}>
                      <div style={styles.top}>{service.title}</div>
                      <img
                        src={service.pic}
                        style={{ borderRadius: "50%" }}
                        alt="大画面ロゴ"
                        height="120"
                        width="120"
                      />
                      <div style={styles.middle}>{service.description}</div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

export default Services;
