import { Link, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import image from "../image/Meeting/bg.jpg";
import Event1 from "../offlineEvents/Event1.jpg";
import Event2 from "../offlineEvents/Event2.jpg";
import EKI from "../offlineEvents/event20240803/Event20240803_1.jpg";
import Header from "../info/Meeting/Header";
import PastEvents from "./PastEvents";
import { marked } from "marked";

const OfflineEventsTop = () => {
  const navigate = useNavigate();

  const markdown = `
## 山手線の駅ウォーキング企画
第4回目は「秋葉原駅」

行ったことがないから歩いてみたい
みんなと話しながら，複数人で歩きたいという方におススメです

年代の近い人と仲良くなりたくて，このイベントを作成しました．
20代-40代の皆さん，趣味や好きなことを一緒に共有しませんか？

ほとんどの方が一人参加です！
初参加でも，参加者同士の班を作るので、孤立してしまうなんて事はありません！
お気軽に参加してください！

## イベント進行
【集合】
集合場所につきましたら
主催者にお声掛けください

【グループ分け】
参加人数から、1グループ3-5人のグループ分けを行います
各グループで簡単な自己紹介をお願いします．

【散歩スタート】
各グループで駅周辺を散策しましょう
行き場所は行先ガチャサイトを用いて、多数決で選びます
https://boukenguild.com/

【解散】
基本的に自由解散ですが、16:00目安で終了となります．

## 参加条件
* 性別を問わずコミニュケーションが取れる方
* ルールやマナーを守れる方
* 営業、勧誘、ナンパ目的でない方

## 注意事項
* スケジュールは状況に応じて変更になる場合が御座います
* 天候不良で開催が困難な時はイベントを中止する場合が御座います
* 遅刻をした場合、イベントに参加できなくなります。(その場合の返金はございません)
* 時間になり次第、お散歩出発いたします
* イベント中で発生した費用については、ご自分で飲食した分をお支払いください。

## 禁止事項
* 勧誘・営業行為は禁止です
* 迷惑行為や他参加者への誹謗中傷も禁止です

イベント中やイベント後も上記の行為があった場合には主催者にご連絡ください                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
 `;

  const styles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
    detailsOne: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "10px 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
      height: "auto",
    },
    tablePosition1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: "50vh",
      margin: "20px",
      marginBottom: "20px",
    },
    tablePosition2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    },
    // テーブル間の隙間スタイル
    tableSpacingTop: {
      marginTop: "50px", // テーブルとフッターの間隔を調整
    },
    tableSpacingBottom: {
      marginBottom: "250px",
    },
    table1: {
      border: "1px solid #333",
      borderCollapse: "collapse" as const, //型を明示的に指定
      position: "relative" as const,
      borderBottom: "1px solid #333",
      textAlign: "center" as const,
      with: "100%",
      padding: "8px",
      fontSize: "16px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
    //navigate("https://lin.ee/0pT8myS");
  };

  return (
    <div>
      <Header
        title="交流会イベント"
        subtitle="らまくと＠オフライン"
        image={image}
      />

      <br />
      <br />
      <br />
      <div style={{ ...styles.title, textAlign: "center" }}>
        <h2>【山手線ウォーキング】駅周辺 散歩 × 出会い</h2>
      </div>
      <br />

      <div style={styles.tablePosition1}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <tbody>
            <tr>
              <td style={{ ...styles.table1, borderTopLeftRadius: "10px" }}>
                日時
              </td>
              <td style={{ ...styles.table1, borderTopRightRadius: "10px" }}>
                2024年08月31日(土) 14:00〜16:00
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>集合時刻</td>
              <td style={styles.table1}>13:55</td>
            </tr>
            <tr>
              <td style={styles.table1}>集合場所</td>
              <td style={styles.table1}>
                <img src={EKI} alt="XXX" style={{ height: "150px" }}></img>
                <br />
                山手線 秋葉原駅　電気街口改札
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.table1, borderBottomLeftRadius: "10px" }}>
                申し込みこちらから
              </td>
              <td style={{ ...styles.table1, borderBottomRightRadius: "10px" }}>
                <img
                  src="https://qr-official.line.me/gs/M_729gokgs_GW.png?oat_content=qr"
                  alt="QRコード"
                  height="150"
                  width="150"
                  onClick={() => {
                    window.open("https://line.me/R/ti/p/@729gokgs", "_blank");
                  }}
                ></img>
                <br />
                <Link color="inherit" href="https://line.me/R/ti/p/@729gokgs">
                  LamactのLineグループ
                </Link>
                <br />
                <br />
                「東京 散歩への参加希望します」とLine送信をお願いします
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <span dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }} />

      <br />
      <br />
      <br />
      <div style={{ ...styles.title, textAlign: "center" }}>
        <h2>過去のイベント</h2>
      </div>
      <PastEvents />
    </div>
  );
};

export default OfflineEventsTop;
