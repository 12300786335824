import "./App.css";
import Top from "./components/views/pages/top/Top";
import LonpaPressRelease from "./components/views/pages/pressrelease/LonpaPressRelease";
import BoukenGuildPressRelease from "./components/views/pages/pressrelease/BoukenGuildPressRelease";
import Philosoph from "./components/views/pages/info/philosophy";
import Pressrelease from "./components/views/pages/top/Pressrelease";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Company from "./components/views/pages/top/Company";
import Member from "./components/views/pages/info/Member";
import ShowEvent from "./components/views/pages/event/ShowEvent";

import MemberHR from "./components/views/pages/info/MemberHR";
import Meeting from "./components/views/pages/info/Meeting";
import Action from "./components/views/pages/info/Action";
import Data from "./components/views/pages/info/Data";

import LINE from "./components/views/pages/communication/LINE";
import SNS from "./components/views/pages/communication/SNS";
import OfflineEventsTop from "./components/views/pages/offlineEvents/offlineEventsTop";
import OnlineEventsTop from "./components/views/pages/onlineEvents/onlineEventsTop";

import Event20230826 from "./components/views/pages/offlineEvents/event20230826/Event20230826";
import Event20231210 from "./components/views/pages/offlineEvents/event20231210/Event20231210";
import Event20240303 from "./components/views/pages/offlineEvents/event20240303/Event20240303";
import Event20240616 from "./components/views/pages/offlineEvents/event20240616/Event20240616";
import Novel from "./components/views/pages/offlineEvents/Novel";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/pressrelease" element={<Pressrelease />} />
        <Route path="/company" element={<Company />} />
        <Route path="/20220924" element={<LonpaPressRelease />} />
        <Route path="/bouken" element={<BoukenGuildPressRelease />} />
        <Route path="/philosoph" element={<Philosoph />} />
        <Route path="/member" element={<Member />} />
        <Route path="/memberhr" element={<MemberHR />} />
        <Route path="/meeting" element={<Meeting />} />
        <Route path="/events" element={<ShowEvent />} />
        <Route path="/offlineEventsTop" element={<OfflineEventsTop />} />
        <Route path="/onlineEventsTop" element={<OnlineEventsTop />} />
        <Route path="/event20230826" element={<Event20230826 />} />
        <Route path="/event20231210" element={<Event20231210 />} />
        <Route path="/event20240303" element={<Event20240303 />} />
        <Route path="/event20240616" element={<Event20240616 />} />
        <Route path="/novel" element={<Novel />} />
        <Route path="/action" element={<Action />} />
        <Route path="/data" element={<Data />} />
        <Route path="/sns" element={<SNS />} />
        <Route path="/line" element={<LINE />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
