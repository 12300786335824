import React, { FC } from "react";

type GoogleSlidesEmbedProps = {
  src: string;
  width?: number;
  height?: number;
};

const GoogleSlidesEmbed: FC<GoogleSlidesEmbedProps> = ({
  src,
  width = 960,
  height = 569,
}) => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <iframe
        src={src}
        width={width}
        height={height}
        frameBorder="0"
        allowFullScreen
        title="Google Slides Presentation"
      ></iframe>
    </div>
  );
};

export default GoogleSlidesEmbed;
