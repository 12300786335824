import React from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

const FadeInSection = styled.div`
  opacity: 0;
  transform: translateY(1vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;

  ${(props) =>
    props.inView &&
    css`
      opacity: 1;
      transform: none;
      visibility: visible;
    `}
`;

function FadeInView(props) {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <FadeInSection inView={inView}>{props.children}</FadeInSection>
    </div>
  );
}

export { FadeInView };
