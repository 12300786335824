import { Link, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import React from "react";
import { symbolName } from "typescript";
import Event2 from "./Event2.jpg";
import Event3 from "./Event20230826_9.jpeg";
import Event4 from "./Event20230826_20.jpeg";
import Event5 from "./Event20230826_28.jpeg";
import Event6 from "./Event20230826_13.jpeg";
import Event7 from "./Event20230826_31 .jpeg";
import Header from "../../info/Meeting/Header";
import image from "../../image/Meeting/bg.jpg";

const Event20230826 = () => {
  const navigate = useNavigate();
  const styles = {
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
    table1: {
      border: "1px solid #333",
      borderCollapse: "collapse" as const, //型を明示的に指定
      position: "relative" as const,
      borderBottom: "1px solid #333",
      textAlign: "center" as const,
      with: "100%",
      padding: "8px",
      fontSize: "16px",
    },
    tableImage: {
      //verticalAlign: "middle",
      //display: "flex",
      width: "100%",
      maxWidth: "450px",
      height: "auto",
    },
    tablePosition1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
      margin: "20px",
      marginBottom: "20px",
    },
    tablePosition2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    },
    // テーブル間の隙間スタイル
    tableSpacingTop: {
      marginTop: "50px", // テーブルとフッターの間隔を調整
    },
    tableSpacingBottom: {
      marginBottom: "250px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Header
        title="交流会イベント"
        subtitle="らまくと＠オフライン"
        image={image}
      />

      <div style={{ ...styles.tablePosition1, ...styles.tableSpacingBottom }}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <caption>イベント詳細</caption>
          <tbody>
            <tr>
              <td style={{ ...styles.table1, borderTopLeftRadius: "10px" }}>
                日時
              </td>
              <td style={{ ...styles.table1, borderTopRightRadius: "10px" }}>
                8/26(土) 12:00～17:00
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>タイムスケジュール</td>
              <td style={styles.table1}>
                受付→自己紹介→昼食→LT→ボードゲーム＆交流
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.table1, borderBottomLeftRadius: "10px" }}>
                開催場所住所
              </td>
              <td style={{ ...styles.table1, borderBottomRightRadius: "10px" }}>
                東京都 文京区 千駄木 SKビル 3Fフロア
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={styles.tableSpacingTop}></div>

      <div style={{ ...styles.tablePosition1, ...styles.tableSpacingBottom }}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <caption>
            <h4>イベント風景</h4>
          </caption>
          <tbody>
            <tr>
              <td style={styles.table1}>料理風景</td>
              <td style={styles.table1}>
                <img src={Event3} alt="料理1" style={styles.tableImage} />
              </td>
              <td style={styles.table1}>
                Lanactメンバーである料理人による豪華手料理
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>自己紹介</td>
              <td style={styles.table1}>
                <img src={Event6} alt="料理1" style={styles.tableImage} />
              </td>
              <td style={styles.table1}>自己紹介から、イベントスタートです</td>
            </tr>
            <tr>
              <td style={styles.table1}>ランチ交流</td>
              <td style={styles.table1}>
                <img src={Event4} alt="ランチ" style={styles.tableImage} />
              </td>
              <td style={styles.table1}>
                おいしい食事をいただき、様々な話題で盛り上がりました
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>わいわい交流ボードゲーム</td>
              <td style={styles.table1}>
                <img
                  src={Event5}
                  alt="ボードゲーム"
                  style={styles.tableImage}
                />
              </td>
              <td style={styles.table1}>
                ランチの後は、ボードゲームでの交流とChatGTPで小説を自動生成するツールを使い小説を作成をしました。
                こちらは、ボードゲームの様子です。
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>わいわい交流ChatGTP</td>
              <td style={styles.table1}>
                <img src={Event7} alt="ChatGTP" style={styles.tableImage} />
              </td>
              <td style={styles.table1}>
                参加者の方が開発したChatGTPで小説を自動生成をするツールを使い、小説を作成しました！
                作成した小説はこちら。自動で面白い小説が作れるってすごいですね！
                作成した小説は <a href="/Novel">こちらです！</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        onClick={() => {
          navigate("/offlineEventsTop");
        }}
      >
        一覧へ戻る
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Event20230826;
