import React from "react";
import { marked } from "marked";

const LonpaPressRelease = () => {
  const markdown = `
  新規サービス「Lonpa（ロンパ）」を提供開始   
  オンライン化が進み，目立つ目的だけのフェイクニュースが増える中，
  課題・問題意識を賛否で整理・深堀できるサイトにより，共感・真贋性をネットに提供する．

  株式会社Lamact  
  2022年9月24日 18時00分  
  
  株式会社Lamact（所在地：東京都江東区、代表取締役CEO：藤田 薫哉）は、  
  新規サイト「Lonpa（ロンパ）」の提供を開始することをお知らせいたします。  
 
  Lamactでは、わかりあえる仲間とともに、わくわく作業ができる<br>
  そんな「わ」を提供するプラットフォームを作成しています。
    
  このたび、Lamactでは、Webサイト運営で築いてきた知見を活かし、新型コロナウイルス感染拡大の影響で，<br>
  人と人との濃密なコミュニケーションが減少している現状に対し，
  インターネットを通じて，より多くの人と，より深い議論で，<br>
  「わかりあえる」ことを目的とした新サービス「Lonpa」をリリースすることを決定いたしました。  
    
  「Lonpa」では、  
  ・賛成/反対の意見出し<br>
  ・意見への投票 ，投票数のリアルタイム反映と可視化  
  ・重複コメントの抑止  
  といった形で，インターネット上のコミュニケーションにおける深堀を可能にしたことで，
  意見の広がり，意見の集まりを，全体として素早く把握することができます．  
    
  このシステムを利用・連携することにより、  
  企業やその他グループによる  意思決定を安価かつ簡単に，オンライン・リアルタイムで行うことが可能となります。                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
 `;

  const serviceTable = [
    { title: "サービス名", content: "Lonpa(ロンパ)" },
    { title: "サービス内容", content: "意見に対する賛否を可視化" },
    { title: "サービスURL", content: "https://lonpa.net " },
    { title: "利用料金", content: "無料" },
    { title: "利用シーン例", content: "・意見を聞いてみたい" },
    { title: "", content: "・意見を主張したい" },
  ];

  const companyTable = [
    { title: "社名", content: "株式会社Lamact" },
    { title: "所在地", content: "東京都江東区" },
    { title: "設立", content: "2019年" },
    { title: "代表者", content: "藤田 薫哉" },
    { title: "公式twitter", content: "https://twitter.com/LamactT" },
  ];

  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }} />
      <table border={1}>
        <tbody>
          {serviceTable.map((item) => (
            <tr>
              <td>{item.title}</td>
              <td>{item.content}</td>
            </tr>
          ))}
        </tbody>
      </table>
      株式会社Lamact 会社概要
      <table border={1}>
        <tbody>
          {companyTable.map((item) => (
            <tr>
              <td>{item.title}</td>
              <td>{item.content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LonpaPressRelease;
