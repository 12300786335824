import List from "./UserList.json";

import {
  Box,
  Grid,
  Avatar,
  Typography,
  Card,
  CardContent,
} from "@mui/material"; // 必要なコンポーネントをインポート

type UserList = {
  name: string;
  avatar: string;
  charge: string;
  values: string;
  hobby: string;
};
const lists: UserList[] = List.user;

const Member = () => {
  return (
    <Box sx={{ marginTop: "5vh", padding: "1rem", backgroundColor: "#f5f5f5" }}>
      {" "}
      <Typography
        variant="h5"
        component="div"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          padding: "1rem",
          backgroundColor: "#1976d2",
          color: "#ffffff",
          borderRadius: "10px",
        }}
      >
        {" "}
        メンバー紹介{" "}
      </Typography>{" "}
      <Grid container spacing={4} sx={{ marginTop: "2rem" }}>
        {" "}
        {lists.map((item, index) => (
          <Grid item xs={12} md={4} key={item.name}>
            {" "}
            <Card
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                boxShadow: 3,
              }}
            >
              {" "}
              <CardContent>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  {" "}
                  <Avatar
                    src={item.avatar}
                    alt={item.name}
                    sx={{
                      width: "75px",
                      height: "75px",
                      marginRight: "1rem",
                      border: "2px solid #1976d2",
                    }}
                  />{" "}
                  <Box>
                    {" "}
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {" "}
                      {item.name}{" "}
                    </Typography>{" "}
                    <Typography variant="body2" color="text.secondary">
                      {" "}
                      担当: {item.charge}{" "}
                    </Typography>{" "}
                  </Box>{" "}
                </Box>{" "}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: "0.5rem" }}
                >
                  {" "}
                  一言: {item.values}{" "}
                </Typography>{" "}
                <Typography variant="body2" color="text.secondary">
                  {" "}
                  趣味: {item.hobby}{" "}
                </Typography>{" "}
              </CardContent>{" "}
            </Card>{" "}
          </Grid>
        ))}{" "}
      </Grid>{" "}
    </Box>
  );
};

export default Member;
