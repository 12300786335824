const Data = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      height: "70vh",
      backgroundColor: "#ffffff",
    },
    title: {
      fontSize: "2.5rem",
      color: "#2c3e50",
      marginBottom: "1rem",
    },
    text: {
      fontSize: "1.5rem",
      color: "#34495e",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Coming Soon</h1>
      <p style={styles.text}>お楽しみに！</p>
    </div>
  );
};

export default Data;
