import { Link, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Event202312_1 from "./Event202312_1.jpeg";
import Event202312_2 from "./Event202312_2.jpeg";
import Event202312_3 from "./Event202312_3.jpeg";
import Event202312_4 from "./Event202312_4.jpeg";
import Event202312_5 from "./Event202312_5.jpeg";
import Event202312_6 from "./Event202312_6.jpeg";
import Event202312_7 from "./Event202312_7.jpeg";
import Header from "../../info/Meeting/Header";
import image from "../../image/Meeting/bg.jpg";

import React from "react";
import { symbolName } from "typescript";

const Event202312 = () => {
  const navigate = useNavigate();
  const styles = {
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
    table1: {
      border: "1px solid #333",
      borderCollapse: "collapse" as const, //型を明示的に指定
      position: "relative" as const,
      borderBottom: "1px solid #333",
      textAlign: "center" as const,
      with: "100%",
      padding: "8px",
      fontSize: "16px",
    },
    tableImage: {
      //verticalAlign: "middle",
      //display: "flex",
      width: "100%",
      maxWidth: "450px",
      height: "auto",
    },
    tablePosition1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: "50vh",
      margin: "20px 20px 20px",
    },
    tablePosition2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: "50vh",
    },
    // テーブル間の隙間スタイル
    tableSpacingTop: {
      marginTop: "50px", // テーブルとフッターの間隔を調整
    },
    tableSpacingBottom: {
      marginBottom: "20px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Header
        title="交流会イベント"
        subtitle="らまくと＠オフライン"
        image={image}
      />
      <div style={{ ...styles.tablePosition1, ...styles.tableSpacingBottom }}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <caption>イベント詳細</caption>
          <tbody>
            <tr>
              <td style={{ ...styles.table1, borderTopLeftRadius: "10px" }}>
                日時
              </td>
              <td style={{ ...styles.table1, borderTopRightRadius: "10px" }}>
                12/10(日) 10:00〜12:00
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>タイムスケジュール</td>
              <td style={styles.table1}>
                原宿駅に集合→アプリを使って散歩コースを決定→わいわいしながら散歩
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.table1, borderBottomLeftRadius: "10px" }}>
                開催場所住所
              </td>
              <td style={{ ...styles.table1, borderBottomRightRadius: "10px" }}>
                原宿周辺
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={styles.tableSpacingTop}></div>

      <div style={{ ...styles.tablePosition1, ...styles.tableSpacingBottom }}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <caption>
            <h4>イベント風景</h4>
          </caption>
          <tbody>
            <tr>
              <td style={styles.table1}>明治神宮へレッツゴー</td>
              <td style={styles.table1}>
                <img
                  src={Event202312_1}
                  alt="明治神宮"
                  style={styles.tableImage}
                />
              </td>
              <td style={styles.table1}>
                原宿のパワースポットへレッツゴー
                皆でご利益をもらいに行きました。
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>代々木公園</td>
              <td style={styles.table1}>
                <img
                  src={Event202312_2}
                  alt="代々木公園"
                  style={styles.tableImage}
                />
              </td>
              <td style={styles.table1}>代々木公園は大きいですね</td>
            </tr>
            <tr>
              <td style={styles.table1}>代々木公園で集合写真を</td>
              <td style={styles.table1}>
                <img
                  src={Event202312_3}
                  alt="集合写真"
                  style={styles.tableImage}
                />
              </td>
              <td style={styles.table1}>
                参加してくださった皆さんと一緒に集合写真を撮りました。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        onClick={() => {
          navigate("/offlineEventsTop");
        }}
      >
        一覧へ戻る
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Event202312;
