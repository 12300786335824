import Adpic from "./image/advertisement.png";

const ShowEvent = () => {
  return (
    <div>
      <img src={Adpic} style={{ width: "95vw", objectFit: "cover" }} />
    </div>
  );
};

export default ShowEvent;
