import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import List from "./PagesList.json";

type PagesList = {
  title: string;
  img: string;
  path: string;
  type: string;
};
type CompanyList = {
  title: string;
  img: string;
  path: string;
  type: string;
};
const allLists: PagesList[] = List.pages;
const companyList: CompanyList[] = allLists.filter((v) => v.type === "company");

const Company = () => {
  return (
    <div>
      <Grid container sx={{ mt: 0, px: 2, pb: 2, backgroundColor: "#f5f5f5" }}>
        {companyList.map((item, index) => (
          <Grid item xs={6} md={4} key={item.title}>
            <Card>
              <CardActionArea href={item.path}>
                <CardMedia
                  component="img"
                  image={item.img}
                  alt="pic"
                  style={{ height: "20vh", objectFit: "cover" }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default Company;
