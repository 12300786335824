import { Typography } from "@mui/material";
import LineQR from "./LineQR.png";

const LINE = () => {
  const styles = {
    container: {
      padding: "1rem",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    content: {
      padding: "1rem",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <div>
      <div
        style={styles.container}
        onClick={() => {
          window.open("https://lin.ee/0pT8myS", "_blank");
        }}
      >
        <img src={LineQR} style={{ height: "50%", objectFit: "cover" }} />
      </div>
      <strong style={styles.content}>
        QRコードをスマホでスキャンするとLINEの友だちに追加できます。スマホの方はQRをクリックすることで遷移できます。
        <br></br>
      </strong>
    </div>
  );
};

export default LINE;
