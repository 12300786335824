import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

type Props = {
  title: string;
  subtitle: string;
  // place: string;
  // date: string;
  // time: string;
  // event: string;
  // price: string;
  image: any;
};

const Header: React.VFC<Props> = ({
  title,
  subtitle,
  // place,
  // date,
  // time,
  // event,
  // price,
  image,
}: Props) => {
  const { width } = useWindowSize();
  const styles: any = {
    masthead: {
      width: "100%",
      height: "200px",
      textAlign: "center",
      color: "white",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "scroll",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundImage: `url(${image})`,
    },
    introLeadIn: {
      width: "100%",
      fontSize: "22px",
      fontStyle: "italic",
      lineHeight: "22px",
      marginBottom: "25px",
    },
    introHeading: {
      width: "100%",
      fontSize: "50px",
      fontWeight: "700",
      lineHeight: "50px",
      marginBottom: "25px",
    },
    placestyle: {
      width: "100%",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      marginBottom: "5px",
    },
    datestyle: {
      width: "100%",
      fontSize: "60px",
      fontWeight: "700",
      lineHeight: "50px",
      marginBottom: "25px",
    },
    eventstyle: {
      width: "100%",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      marginBottom: "5px",
    },
    introLeadIn2: {
      width: "100%",
      fontSize: "18px",
      fontStyle: "italic",
      lineHeight: "22px",
      marginBottom: "25px",
    },
    introHeading2: {
      width: "100%",
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "32px",
      marginBottom: "25px",
    },
    placestyle2: {
      width: "100%",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      marginBottom: "5px",
    },
    datestyle2: {
      width: "100%",
      fontSize: "45px",
      fontWeight: "700",
      lineHeight: "45px",
      marginBottom: "25px",
    },
    eventstyle2: {
      width: "100%",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "30px",
      marginBottom: "5px",
    },
  };

  return (
    <>
      {width > 650 ? (
        <>
          {" "}
          <header style={styles.masthead}>
            <div style={{ paddingTop: "50px", paddingBottom: "100px" }}>
              {/* <div style={styles.introLeadIn}>{title}</div>
              <div style={styles.introHeading}>{subtitle}</div>

              <div style={styles.datestyle}>{date}</div>
              <div style={styles.placestyle}>{time + "   " + place}</div>
              <div style={styles.eventstyle}>{event}</div>
              <div style={styles.eventstyle}>{price}</div> */}

              <div style={styles.introLeadIn}>{title}</div>
              <div style={styles.introHeading}>{subtitle}</div>
            </div>
          </header>
        </>
      ) : (
        <>
          {" "}
          <header style={styles.masthead}>
            <div style={{ paddingTop: "50px", paddingBottom: "100px" }}>
              <div style={styles.introLeadIn2}>{title}</div>
              <div style={styles.introHeading2}>{subtitle}</div>

              {/* <div style={styles.datestyle2}>{date}</div>
              <div style={styles.placestyle2}>{time + "   " + place}</div>
              <div style={styles.eventstyle2}>{event}</div>
              <div style={styles.eventstyle2}>{price}</div> */}
            </div>
          </header>
        </>
      )}
    </>
  );
};
export default Header;
