import RecommendService from "./RecommendService";
import Contents0 from "./Contents0";
import Contents1 from "./Contents1";
import Contents2 from "./Contents2";
import Contents3 from "./Contents3";
import Typography from "@mui/material/Typography";

import { FadeInView } from "../../../helper/FadeInView";
import { Marquee } from "../../../helper/Marquee";

function Top() {
  // 楕円の下部分のスタイル（既に動いているもの）
  const ellipseContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "50px", // 楕円の高さ
    backgroundColor: "#a6d4fa", // 楕円の上部分の背景色
    overflow: "hidden",
  };

  const ellipseStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "-25px", // 楕円の下部分を見せるために調整
    width: "100%",
    height: "50px", // 楕円の高さ
    backgroundColor: "#ffffff", // 楕円の下部分の背景色
    borderTopLeftRadius: "100%",
    borderTopRightRadius: "100%",
  };

  // 楕円の上部分バージョンのスタイル
  const topEllipseContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "50px", // 楕円の高さ
    backgroundColor: "#a6d4fa", // 楕円の下部分の背景色
    overflow: "hidden",
  };

  const topEllipseStyle: React.CSSProperties = {
    position: "absolute",
    top: "-25px", // 楕円の上部分を見せるために調整
    width: "100%",
    height: "50px", // 楕円の高さ
    backgroundColor: "#ffffff", // 楕円の上部分の背景色
    borderBottomLeftRadius: "100%",
    borderBottomRightRadius: "100%",
  };

  const parentR = 450; // 親要素の半径 (300pxの幅を基準)
  const text = " 見えない物語を歩む"; // 表示するテキスト
  const chars = text.split(""); // テキストを一文字ずつ分割
  const angle = 5; // 角度設定

  // 親要素のスタイル
  const circleTextStyle: React.CSSProperties = {
    width: "100%",
    height: "0px",
    borderRadius: "50%",
    // background: "#fff",
    color: "#000",
    margin: "0 auto",
    position: "relative",
    fontSize: "2.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div>
      <FadeInView>
        <FadeInView>
          <RecommendService />
        </FadeInView>
      </FadeInView>

      <div style={circleTextStyle}>
        {chars.map((char, i, arr) => (
          <span
            key={i}
            style={{
              position: "absolute",
              left: "50%",
              top: "260px",
              transformOrigin: "left top",
              transform: `rotate(${
                i * angle - angle * Math.floor(arr.length / 2)
              }deg) translate(0%, 40%) translate(0, -${parentR}px) translate(-50%, -50%)`,
              display: "inline-block",
            }}
          >
            {char}
          </span>
        ))}
      </div>

      <FadeInView>
        <Contents2 />

        {/* 楕円の下部分だけを表示して背景色を分ける */}
        <div style={ellipseContainerStyle}>
          <div style={ellipseStyle}></div>
        </div>
      </FadeInView>
      <FadeInView>
        <Contents0 />

        {/* 楕円の上部分 */}
        <div style={topEllipseContainerStyle}>
          <div style={topEllipseStyle}></div>
        </div>
      </FadeInView>
      <FadeInView>
        <Contents3 />
      </FadeInView>
    </div>
  );
}

export default Top;

{
  /* 
      <Typography
        variant="h6"
        align="center"
        sx={{ color: "#dddddd" }}
        component="div"
      >
        <Marquee limitWidth={1}>作成中なので信頼しないでね</Marquee>
      </Typography>

      <FadeInView>
        <Contents1 />
      </FadeInView> */
}
