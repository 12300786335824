import { Link, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import image from "../image/Meeting/bg.jpg";
import Event1 from "../offlineEvents/Event1.jpg";
import Event2 from "../offlineEvents/Event2.jpg";
import EKI from "../offlineEvents/event20240303/20240303_3.jpg";
import Header from "../info/Meeting/Header";

const OfflineEventsTop = () => {
  const navigate = useNavigate();
  const styles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
    detailsOne: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "10px 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
      height: "auto",
    },
    tablePosition1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: "50vh",
      margin: "20px",
      marginBottom: "20px",
    },
    tablePosition2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    },
    // テーブル間の隙間スタイル
    tableSpacingTop: {
      marginTop: "50px", // テーブルとフッターの間隔を調整
    },
    tableSpacingBottom: {
      marginBottom: "250px",
    },
    table1: {
      border: "1px solid #333",
      borderCollapse: "collapse" as const, //型を明示的に指定
      position: "relative" as const,
      borderBottom: "1px solid #333",
      textAlign: "center" as const,
      with: "100%",
      padding: "8px",
      fontSize: "16px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
    //navigate("https://lin.ee/0pT8myS");
  };

  return (
    <div>
      <Header title="勉強会イベント" subtitle="React勉強会" image={image} />

      <div style={styles.tablePosition1}>
        <table style={{ ...styles.table1, borderSpacing: "0px" }}>
          <caption>イベント詳細</caption>
          <tbody>
            <tr>
              <td style={{ ...styles.table1, borderTopLeftRadius: "10px" }}>
                日時
              </td>
              <td style={{ ...styles.table1, borderTopRightRadius: "10px" }}>
                2024年03月03日(日) 10:00〜11:30
              </td>
            </tr>
            <tr>
              <td style={styles.table1}>開催場所</td>
              <td style={styles.table1}>Discordを利用</td>
            </tr>
            <tr>
              <td style={{ ...styles.table1, borderBottomLeftRadius: "10px" }}>
                申し込みこちらから
              </td>
              <td style={{ ...styles.table1, borderBottomRightRadius: "10px" }}>
                <img
                  src="https://qr-official.line.me/gs/M_729gokgs_GW.png?oat_content=qr"
                  alt="QRコード"
                  height="150"
                  width="150"
                  onClick={() => {
                    window.open("https://line.me/R/ti/p/@729gokgs", "_blank");
                  }}
                ></img>
                <br />
                <Link color="inherit" href="https://line.me/R/ti/p/@729gokgs">
                  LamactのLineグループ
                </Link>
                <br />
                <br />
                「React勉強会への参加希望します」とLine送信をお願いします
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfflineEventsTop;
