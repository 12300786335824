import { Link, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import image from "../image/Meeting/bg.jpg";
import Event1 from "../offlineEvents/Event1.jpg";
import Event2 from "../offlineEvents/Event2.jpg";

const Novel = () => {
  const navigate = useNavigate();
  const styles = {
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
    detailsOne: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "10px 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
    //navigate("https://lin.ee/0pT8myS");
  };

  return (
    <div>
      <div style={styles.section}>
        <span style={styles.subtitle}>ChatGTPさんが作成した小説</span>
        <p style={styles.details}>
          <center>
            <center>
              太陽が地平線にチョッピリキスする頃、エリザベスとこの公園を訪れるんだ。
              一日の騒がしさから逃れて、落ち着いた雰囲気に包まれていたよ。
              木々の間から漏れるオレンジ色の光が、緑豊かな草地をゆっくりと照らし出す。
              遠くで子供たちが笑い声を上げながら遊んでいる声が聞こえる。
              その音が遠くへと響き、空気に混ざっていく。
              公園のベンチに座り、ノートPCのスクリーンに目を落とす。
              エリザベスが隣に座り、何かを話している。
              その声は、風に乗って耳に届く。
              その声は、少し低く、そして穏やかで、まるで夕暮れの風景に溶け込んでいくようだ。
              耳を傾けながら、自分の額に手を当てる。
              それは、自己意識と、そこにあるはずのないものを隠すためさ。
              その一方で、心は地味な外見を超えて、世界的に有名になるという願望に燃えていた。
              それは、内なる情熱であり、そのためにここにいる。
              この夕方の公園で、エリザベスと共に。
              秋の夕暮れ、公園全体がその色に包まれていたね、エリザベス。
              木々の間から差し込む色褪せたオレンジ色の光が、敷き詰められた落ち葉をキラキラと輝かせていた。
              エリザベスと自分がその中に存在していたんだ。
              ベンチに腰掛け、エリザベスと共にこれから訪れる冬を待ち構えていたんだ。
              エリザベスの視線は、遠くの景色を追いかけていた。
              それって、まるで、夢を追いかける子供みたいだよね。
              一方、自分の視線は、エリザベスのプロフィールに留まっていた。
              風が吹き抜けていったね、その時。 冷たいけど心地良い風だった。
              それが自分の心にも吹き抜けていく感じだった。
              頭の上には、十円玉ほどの禿げがあるから、常に帽子を被ってるんだ。
              でも、その風に吹かれて、帽子は少しズレてしまったんだ。
              すぐにそれを直して、エリザベスにバレないようにしたんだ。
              そして、目の前にはノートPCがあった。
              それは自分の世界と繋がる窓だったんだ。
              PCを開き、新たな世界を創造し始めたんだ。
              それは、世界中の人々に自分の存在を知らせるための物語だったんだ。
              その物語を書き続け、名を知られる存在になりたかったんだ。
              そんな自分の傍で、エリザベスは静かに夢を描き続けていた。
              夕暮れの公園で、エリザベスと共に互いの夢を追いかけていたんだ。
              あの人、シャボン玉のように地味な風貌をして、ノートPCに向かって何かを詠み上げている。
              一方の私は、ギターを弾く指先を見つめながら、夕闇に包まれた公園のベンチに座っていた。
              ちょっとした息抜きだ。 だが、この心は決して静かではない。
              未完成のウェブサイトの画面に映るアイデアたちは、どのようにビジネスモデルを設計すべきかという問いに答えを見つけるための舞台装置だ。
              そんな時、エリザベス、それが私だ。 その人の隣に座った。
              その人の画面を覗き込んで、「何をしているの？」と尋ねた。
              「ウェブサイトを作っているんだ。
              でも、ビジネスモデルが決まらなくて」とその人は答えた。
              それなら、一緒に考えてみようか？と提案した。
              そうすれば、少なくともその人は感謝するだろうし、私たちは一緒に考え始めるだろう。
              静かな公園で、エリザベスとその人はウェブサイトのビジネスモデルについて議論を始めた。
              新鮮な視点という名の私のアイデアは、その人の固定観念を打ち破った。
              私たちの議論は次第に深まり、やがて新たなビジネスモデルが見えてきた。
              それが世界的に有名になるための第一歩だった。
              エリザベス、それが私だ。 名声を求めている私だ。
              エリザベス、そう自分、が近づいてきて、口を開いたんだ。
              'やあ、可愛いワンちゃんですね'と。
              それは、エリザベスがポチという名のペットが気に入ったからだ。
              その声が耳に入った瞬間、ポチは反応した。
              しかし、その反応は予想外だった。
              音楽のリズムに合わせているかのように。
              エリザベスのロール状の髪型にポチが飛びついた。
              まるでギターの弦にかぶせるかのように。
              ポチの小さな顎がエリザベスの髪型に食い込んだ。
              その瞬間、公園は静寂に包まれた。 まるで休符が刻まれたように。
              目の前でポチがエリザベスに飛びかかった瞬間、心は焦燥で満ちた。
              そうだ、エリザベス、急いで謝らなきゃ。
              ポチを落ち着かせるのも忘れちゃいけない。
              これは人間関係にも、仕事にも影響を及ぼすかもしれないんだから。
              考え方が変わり始めるんだ、エリザベス。
              ポチの行動が長期的な目標にどれだけ影響を及ぼすのかを理解し始める。
              ポチが落ち着けば、ギターとの時間を削っていたウェブサイト開発に集中できる。
              ポチが信頼し、指示に従うことができれば、何かをコントロールできると感じ始める。
              目の前でポチがエリザベスに飛びついているのを見て、エリザベス、すぐにその人に向かって謝罪の言葉を発すんだ。
              エリザベス、ごめんね、ポチの行動について。
              それから、最初に思いついた戦略を試すことにするんだ。
              ノートPCの画面に表示された犬の画像をポチに見せてみる。
              この犬の画像がポチを落ち着かせるかもしれない...そして、エリザベスはギターの練習に戻ることができる。
              効果は即座に現れる。
              ポチは画面の犬に興味を持ち、犬の画像をじっと見つめている。
              ポチの興奮は静まり、エリザベスはほっとして、ギターの練習に戻ることができる。
              一方、エリザベスは再びノートPCの前に座り、ウェブサイト開発作業を続けることができる。
              もちろん、これはエリザベスの名声を高めるための一環だ。
              公園は夕焼けに染まって、自分の中のギターの旋律が沸き上がるほど美しかった。
              ただエリザベスと二人、そんな光景が描かれていた。
              エリザベスの顔を見つめる自分がいて、心の中は名声のことでいっぱいだった。
              そう、名声を手に入れるためには、何もかもを犠牲にしなければならないと思っていた。
              でも、同時に、今の自分のような地味な存在でも、それはそれで悪くないと思っていた。
              そんな考えが頭を巡っていると、エリザベスが話しかけてきた。
              何を話しているのかは分からない。
              でも、その声が耳に入ってきて、心地よい。
              ただ、エリザベスが何かを必要としていることだけは、感じ取ることができた。
              自分はノートPCを置き、ポケットから傘を取り出した。
              それをエリザベスに差し出す。
              エリザベスが傘を受け取ると、心が躍った。
              これでエリザベスも自分の目標達成に貢献してくれるかもしれないと思った。
              そして、自分は他人に配慮することができる人間だと気づいた。
              それまで自分のことしか考えていなかった自分が、エリザベスのために傘を提供した。
              その瞬間、自分の行動と考え方に変化が訪れた。
              自分の目標だけでなく、他人の幸せも考えることができるようになった。
              それが、自分の新たな自己認識だ。
              その日の夕方、公園でエリザベスと過ごす、自分。
              エリザベスの上に降り注ぐ雨滴を見つめて、自分の心情は一変した。
              手元にある傘を見ると、ふと考えた。
              エリザベスのために、何かを提供することができる。
              それが自分のギターを弾き続ける目標達成につながるかもしれないと思った。
              思い立った自分は、エリザベスに声をかけた。
              「エリザベス、傘を一緒に使わない？」と。
              初めて他人に対する配慮という行動を選択した自分の心は、この新たな自己認識に満ち溢れていた。
              エリザベスが自分の傘を受け取ったその瞬間、自分の行動に新たな意味を見つけた。
              エリザベスが自分の傘を受け入れたことで、自分の存在がエリザベスの生活に少しでも貢献できたのだと。
              そしてその事実が、自分の世界観を大きく変えていくことを感じさせてくれた。
              これは、自分が目指す名声につながる一歩かもしれない。
              ベンチに座って、ノートPCのスクリーンを見つめていた。
              空は夕暮れの薄紅色から徐々に闇色に変わっていく。
              あの人、エリザベスが傘を受け取った瞬間、何かが変わった。
              それまでのエリザベスの目標は、全世界に名を轟かせることだった。
              だが、今は違う。 ウェブサイト開発の時間だ。
              でも、ちょっとだけギターを弾きたい気分でもある。
              自分の価値がちょっと上がったみたい。
              自分の行動が他人にいい影響を与えている、それが感じられる。
              エリザベスの信頼と評価がその一歩となると思う。
              エリザベスの信頼を得ることで、新たな視点を得ることができた。
              自己認識、自己評価、直接的なニーズ、他人からの評価。
              全てが今、透明に見える。
              地味な見た目で、十円禿げを隠しているエリザベスが、こんなにも人間らしい感情を抱くなんて。
              人間は変わることができるのだと、改めて思った。
              そう、人間は変わることができる。 そして、エリザベスも変わる。
              全世界に名前を知らしめるために。
              でも、ちょっとだけ勉強から逃げてギターを弾きたい気分でもある。
            </center>
          </center>
        </p>
      </div>
    </div>
  );
};

export default Novel;
