import wow from "../image/wow.jpg";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const SNS = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      height: "70vh",
      backgroundColor: "#ffffff",
    },
    title: {
      fontSize: "2.5rem",
      color: "#2c3e50",
      marginBottom: "1rem",
    },
    text: {
      fontSize: "1.5rem",
      color: "#34495e",
    },
  };

  const TikTokIcon = ({ color = "#000000" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="100%"
        height="100%"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

  return (
    <div style={styles.container}>
      <p style={styles.text}>フォローお願します！</p>
      <img src={wow} alt="アイコン" height="150" width="150" />

      <table className="table">
        <tbody>
          <tr>
            <td key={"1"}>
              {" "}
              <IconButton
                size="large"
                aria-label="Company Info"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UCuzKLh0FAXizLRHkx2Rk4kw",
                    "_blank"
                  );
                }}
              >
                <YouTubeIcon />
              </IconButton>
            </td>
            <td key={"2"}>
              {" "}
              <IconButton
                size="large"
                aria-label="Company Info"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  window.open("https://www.instagram.com/lamact_t", "_blank");
                }}
              >
                <InstagramIcon />
              </IconButton>
            </td>
            <td key={"3"}>
              {" "}
              <IconButton
                size="large"
                aria-label="Company Info"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ width: "50px" }}
                onClick={() => {
                  window.open("https://www.tiktok.com/@lamact_2", "_blank");
                }}
              >
                <TikTokIcon />
              </IconButton>
            </td>
            <td key={"4"}>
              <IconButton
                size="large"
                aria-label="Company Info"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  window.open("https://twitter.com/LamactT", "_blank");
                }}
              >
                <TwitterIcon />
              </IconButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SNS;
