import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "./Meeting/Header";
import Services from "./Meeting/Services";
import Timeline from "./Meeting/Timeline";
import image from "../image/Meeting/bg.jpg";

const Meeting = () => {
  const navigate = useNavigate();
  const styles = {
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#444444",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
      color: "#444444",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
      padding: "1rem",
    },
    section: {
      marginTop: "3rem",
      padding: "0.5rem 1rem 0 1rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "0px",
      marginTop: "30px",
      backgroundColor: "#1976d280",
      textShadow: "#0000ff 1px 0 1px",
      padding: "0.5rem 1rem 0 1rem",
      borderRadius: "10px 10px 0 0",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      color: "#444444",
      border: "solid 1px #EEE ",
      borderRadius: "0 10px 10px 10px",
      backgroundColor: "#EEE",
      padding: "10px 20px",
      boxShadow: "10px 5px 5px #DDD",
      marginTop: "0px",
    },
  };

  const handleLink = () => {
    window.scrollTo(0, 0);
    //navigate("https://lin.ee/0pT8myS");
  };

  return (
    <div>
      <Header
        title="交流会イベント"
        subtitle="らまくと＠オフライン"
        // place="in 東京 千駄木"
        // date="8/26(土)"
        // time="12:00～17:00"
        // event="料理人招いてランチ・雑談・ボードゲーム等"
        // price="5000円"
        image={image}
      />

      <Services />
      {/* <Timeline /> */}

      <h1 style={{ ...styles.title, textAlign: "center" }}>イベント詳細</h1>

      <h3 style={{ ...styles.title, textAlign: "center" }}>Comming Soon</h3>
      {/* <div style={styles.section}>
        <span style={styles.subtitle}>日時</span>
        <p style={styles.details}>8/26(土) 12:00～17:00</p>
      </div>

      <div style={styles.section}>
        <span style={styles.subtitle}>参加方法</span>
        <div style={styles.details}>
          <Link href="https://lin.ee/0pT8myS" color="#6495ed">
            LamactのLINE公式アカウント{" "}
          </Link>
          にご参加お願いいたします
          <br />
          　※登録後、参加方法についてご案内いたします
          <br />
          <br />
          <Link
            href="https://line.me/R/ti/p/@729gokgs"
            color="#6495ed"
            // onClick={handleLink}
          >
            https://line.me/R/ti/p/@729gokgs
          </Link>
          <br />
        </div>
      </div>

      <div style={styles.section}>
        <span style={styles.subtitle}>費用</span>
        <div style={styles.details}>
          5000円　(場所代・料理代・ボードゲーム代 込み)
          <br />
          Stripeにて事前のお支払いをいただいております
          <br />
          上記LINEよりDMにて支払い場所をご案内いたします
        </div>
      </div>

      <div style={{ ...styles.section }}>
        <span style={styles.subtitle}>設備</span>
        <p style={styles.details}>
          ・ 電源 <br />
          ・ ボードゲーム <br />
        </p>
      </div>

      <div style={{ ...styles.section, marginBottom: "80px" }}>
        <span style={styles.subtitle}>開催場所</span>
        <p style={styles.details}>
          東京都 文京区 <br />
          千駄木 SKビル 3Fフロア
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.0667418056155!2d139.75981777608519!3d35.72457707257328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dcd8b6880a9%3A0x8ea18dee9c70825b!2z44CSMTEzLTAwMjIg5p2x5Lqs6YO95paH5Lqs5Yy65Y2D6aeE5pyo77yS5LiB55uu77yS77yY4oiS77yR77yUIO-8s--8q-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1691363857874!5m2!1sja!2sjp"
              width="100%"
              height="450"
              style={{ border: "0", alignItems: "center" }}
              loading="lazy"
            ></iframe>
          </div>
        </p>
      </div> */}
    </div>
  );
};

export default Meeting;
