import { Link } from "react-router-dom";

const MemberHR = () => {
  const styles = {
    container: {
      padding: "2rem",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    description: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
    },
    section: {
      marginTop: "1.5rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    details: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>メンバー募集</h1>
      <p style={styles.description}>
        当社では、優秀なメンバーを募集しています！以下の職種に興味がある方は、ぜひ応募してください。
      </p>

      <div style={styles.section}>
        <h2 style={styles.subtitle}>デザイナー</h2>
        <p style={styles.details}>
          優れたデザイナーを探しています。以下のスキルが求められます：
        </p>
        <ul>
          <li>UI/UXデザインの経験</li>
          <li>HPのデザインに知見がある方</li>
          <li>コミュニケーション能力と協調性</li>
        </ul>
      </div>

      {/* Existing content */}
      <div style={styles.section}>
        <h2 style={styles.subtitle}>応募方法</h2>
        <p style={styles.details}>
          応募するには、以下の公式LINEにお問い合わせください。
          <br />
          公式LINEは<Link to={"https://lin.ee/0pT8myS"}>こちら</Link>。
        </p>
      </div>
    </div>
  );
};

export default MemberHR;
