import React, { useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import styles from "../../styles.module.css";
import List from "./PictureList.json";

type ShowList = {
  text: string;
  img: string;
  // path: string;
};
const lists: ShowList[] = List.images;
const slides = lists.map((v) => v.img);
const texts = lists.map((v) => v.text);

const RecommendService = () => {
  const [index, setIndex] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1200 },
    onRest: (_a: any, _b: any, item: number) => {
      if (index === item) {
        setTimeout(() => {
          setIndex((state) => (state + 1) % slides.length);
        }, 4200); // 2000ミリ秒 = 2秒
      }
    },
    exitBeforeEnter: true,
  });

  const containerStyle: React.CSSProperties = {
    position: "relative", // 親要素を基準に絶対配置するため
  };

  const overlayStyle: React.CSSProperties = {
    position: "absolute", // 絶対配置で親要素全体に重ねる
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)", // 白色で50%透明
    zIndex: 10, // 前面に出すために高い値を設定
  };

  const textStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "20px", // 下から20pxの余白を追加
    maxWidth: "80%", // 横幅を80%に制限
    margin: "0 auto", // 中央揃えにする
    left: "0",
    right: "0",
    color: "#000",
    backgroundColor: "rgba(255, 255, 255, 0.4)", // 背景を半透明にする
    padding: "10px",
    textAlign: "center",
    borderRadius: "20px", // 角を少し丸める
    fontSize: "18px", // 文字サイズを大きくする
    zIndex: 20, // オーバーレイよりも前面に表示されるように設定
  };

  return (
    <div className="flex center" style={{ overflow: "hidden" }}>
      {transitions((style: any, i: number) => (
        <div style={containerStyle}>
          <animated.div
            className={styles.bg}
            style={{
              ...style,
              backgroundImage: `url(${slides[i]})`,
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              height: "30vh",
              maxHeight: "400px",
              position: "relative",
            }}
          >
            <div style={overlayStyle}></div>
            {/* 画像の上に表示する文字 */}
            <div style={textStyle}>
              {texts[i]} {/* 現在の画像に対応するテキストを表示 */}
            </div>
          </animated.div>
        </div>
      ))}
    </div>
  );
};
export default RecommendService;
